import React from 'react'
import First from "../components/BenifitsForYou/First"
import Second from "../components/BenifitsForYou/Second";
import Third from "../components/BenifitsForYou/Third";
import Fourth from "../components/BenifitsForYou/Fourth";
import Fifth from "../components/BenifitsForYou/Fifth";
const BenefitsForYou = () => {
  return (
    <div>
      <First />
      <Second />
      <Third />
      <Fourth />
      <Fifth/>
    </div>
  )
}

export default BenefitsForYou