import React from 'react'
import Container1 from '../components/Eligibility/Container1'
import Container2 from '../components/Eligibility/Container2'
import Container3 from '../components/Eligibility/Container3'
import Container4 from '../components/Eligibility/Container4'

const Eligibility = () => {
  return (
    <div>
      <Container1 />
      <Container2 />
      <Container3 />
      <Container4/>
    </div>
  )
}

export default Eligibility