
import React, { Fragment } from 'react'


const First = () => {
  return (
    <Fragment>
      <div className="bg-danger heading d-flex justify-content-center align-items-center">
        <h1 className="text-white">Benefits For You</h1>
      </div>
    </Fragment>
  );
}

export default First