import React from 'react'
import "./Eligibility.css"

const Container1 = () => {
  return (
    <div className="bg-danger heading d-flex justify-content-center align-items-center">
      <h1 className="text-white">Eligibility</h1>
    </div>
  );
}

export default Container1