import React from 'react'

const Footer = () => {
  return (
    <div className="container-fluid mt-auto ">
      <div className="row text-white bg-dark py-3 px-2">
        <div className="col-9">
          © 2023, Car Loans & Sales - A Product by GetFin Tech Solutions. All
          right reserved
        </div>
        <div className="col-3">Powered by Car Loans & Sales </div>
      </div>
    </div>
  );
}

export default Footer