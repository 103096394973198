import React from "react";
import Box1 from "../components/InterestRates/Box1";
import Box2 from "../components/InterestRates/Box2";


const InterestRates = () => {
  return (
    <div>
      <Box1 />
      <Box2/>
    </div>
  );
};

export default InterestRates;
